import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import momenttz from '../../../helpers/momenttz'
import DateTime from 'react-datetime'
import Autocomplete from 'react-autocomplete'
import { connect } from 'react-redux'
// import Toggle from 'react-toggle'
import momentDurationFormatSetup from 'moment-duration-format'

momentDurationFormatSetup(moment)

import CustomButton from '../../../CustomButton'
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/flatpickr.min.css"
import ModalDelete from '../ModalDelete'
import DetailsScenario from './DetailsScenario'

import { apiCallGet } from '../../../../action/RouterActions'
import { requestEventVideo } from '../../../../action/AlertActions'

import './CustomVideoRequest.sass'

const CustomVideoRequest = (props) => {
	const { devices, propsSelectedDevice, propsSelectedDate, user, company, onHide, onSave, toggleLoader } = props
	// console.log('PROPS CustomVideoRequest: ', props)
	const userRole = user.roles[0]
	const partnerName = company.partner || user.partner_company_name
	const companyType = company.type || user.company_type

	const inputVehicleRef = useRef(null)

	const [scenario, setScenario] = useState('simple')
	const [selectedDevice, setSelectedDevice] = useState()
	const [videoDate, setVideoDate] = useState(momenttz())
	const [availableVideoDates, setAvailableVideoDates] = useState([])
	const [availableVideos, setAvailableVideos] = useState([])
	const [startTime, setStartTime] = useState(null)
	const [endTime, setEndTime] = useState(null)
	const [autoValueDevice, setAutoValueDevice] = useState('')
	// const [formatStartTime, setFormatStartTime] = useState('am')
	// const [formatEndTime, setFormatEndTime] = useState('am')
	// const [isDisabledTime, setIsDisabledTime] = useState(false)
	const [showMultipleRequestsModal, setShowMultipleRequestsModal] = useState(false)
	const [showMakeAnotherRequestModal, setShowMakeAnotherRequestModal] = useState(false)
	const [errMessage, setErrMessage] = useState({
		err_vehicle: '',
		err_start_time: '',
		err_end_time: '',
	})

	// console.log('STATES videoDate: ', videoDate)

	useEffect(() => {
		inputVehicleRef.current?.focus()

		if (propsSelectedDevice) {
			setSelectedDevice(propsSelectedDevice)
		}
		if (propsSelectedDate) {
			setVideoDate(propsSelectedDate)
		}
	}, [])

	useEffect(() => {
		// setVideoDate(momenttz())
		setAvailableVideoDates([])
		// if (scenario === 'details') {
		// 	setStartTime('')
		// 	setEndTime('')
		// }

		if (selectedDevice) {
			getAvailableVideDates()
		}
	}, [selectedDevice])

	useEffect(() => {
		// if (scenario === 'details') {
		// setStartTime('')
		// setEndTime('')
		if (selectedDevice && videoDate) {
			getAvailableVideos()
		}
		// }
		//  else {
		// 	setIsDisabledTime(false)
		// }
	}, [videoDate, scenario])

	const getAvailableVideDates = () => {
		toggleLoader(true)
		apiCallGet('/devices/{deviceId}/availablevideo/dates', { deviceId: selectedDevice.device_id })
			.then(res => {
				setAvailableVideoDates(res.data.response.available_video_dates)
				toggleLoader(false)
			})
			.catch(error => {
				console.log('!getAvailableVideDates error: ', error.response, error)
				toggleLoader(false);
				let errDescription = 'An unexpected error to get available video dates. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getAvailableVideos = () => {
		toggleLoader(true)
		const params = {
			device_id: selectedDevice.device_id,
			start_time: momenttz(moment(videoDate).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_time: momenttz(moment(videoDate).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss')
		}
		apiCallGet('/devices/{device_id}/availablevideo', params)
			.then(res => {
				toggleLoader(false)

				let { available_video } = res.data.response[0]

				// replaces the all values '3' with the previous value in available_video string
				let pos = available_video.indexOf('3')
				while (pos > -1) {
					if (pos === 0) {
						available_video = available_video.substring(0, pos) + '0' + available_video.substring(pos)
						available_video[pos] = '0'
					}
					else {
						available_video = available_video.substring(0, pos) + available_video[pos - 1] + available_video.substring(pos + 1)
					}
					pos = available_video.indexOf('3')
				}

				setAvailableVideos(available_video || [])
			})
			.catch(error => {
				toggleLoader(false)
				console.log('getAvailableVideos error: ', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const onDateChanged = (current) => {
		// console.log('!!!!onDateChanged: ', current)
		if (!moment(current, 'MM-DD-YYYY', true).isValid()) {
			toastr.error('', 'Please enter valid date value.')
			return
		}

		setVideoDate(current)
		if (availableVideoDates.length && availableVideoDates.includes(current.format('YYYY-MM-DD'))) {
			setScenario('details')
		}
	}

	// const getPreviousEvents = () => {
	// 	const pos = _.indexOf(availableVideoDates, videoDate)
	// 	if (pos === -1 && availableVideoDates.length) {
	// 		const arr = availableVideoDates.filter((date) => {
	// 			return (momenttz(date).format('YYYY-MM-DD') < momenttz(videoDate).format('YYYY-MM-DD'))
	// 		})

	// 		if (arr.length) {
	// 			setVideoDate(arr[arr.length - 1])
	// 		} else {
	// 			toastr.info('', `No previous clips available`)
	// 		}
	// 	} else if (pos > 0) {
	// 		setVideoDate(availableVideoDates[pos - 1])
	// 	} else {
	// 		toastr.info('', `No previous clips available`)
	// 	}
	// }

	// const getNextEvents = () => {
	// 	const pos = _.indexOf(availableVideoDates, videoDate)
	// 	if (pos === -1 && availableVideoDates.length) {
	// 		const arr = availableVideoDates.filter((date) => {
	// 			return (momenttz(date).format('YYYY-MM-DD') > momenttz(videoDate).format('YYYY-MM-DD'))
	// 		})

	// 		if (arr.length) {
	// 			setVideoDate(arr[0])
	// 		} else {
	// 			toastr.info('', `No future clips available`);
	// 		}
	// 	} else if (pos > -1 && pos < (availableVideoDates.length - 1)) {
	// 		setVideoDate(availableVideoDates[pos + 1])
	// 	} else {
	// 		toastr.info('', `No future clips available`)
	// 	}
	// }

	const getDurationSeconds = () => {
		let res = 0

		if (startTime && endTime) {
			const time1 = new Date(`1970-01-01T${startTime}Z`)
			const time2 = new Date(`1970-01-01T${endTime}Z`)

			res = (time2 - time1) / 1000
		}

		return res
	}

	const durationFormat = () => {
		let diffSeconds = 0
		let diffMinutes = 0

		if (startTime && endTime) {
			const diffMilliseconds = getDurationSeconds()

			diffSeconds = Math.floor((diffMilliseconds) % 60)
			diffMinutes = Math.floor((diffMilliseconds / 60))
		}

		return (
			<>
				<div>
					<label>Minutes</label>
					<div>{diffMinutes}</div>
				</div>
				<div>
					<label>Seconds</label>
					<div>{diffSeconds}</div>
				</div>
			</>
		)
	}

	const checkForm = (videosArg) => {
		let errMessage = ''

		videosArg.forEach((videoitem) => {
			if (!videoitem.device_id) {
				errMessage = 'Select vehicle'
			}

			const diff = videoitem.end_ts - videoitem.start_ts
			console.log('diff: ', diff)
			if (diff < 5) {
				if (videosArg.length > 1) {
					errMessage = 'Duration of your last request is less 5 seconds'
				}
				else {
					errMessage = 'Duration of your request is less 5 seconds'
				}
			}
		})

		if (errMessage) {
			toastr.error(errMessage)
		}

		return !!errMessage
	}

	const onClickRequestVideo = () => {
		const requestDuration = durationSeconds
		// If the request duration is longer than 5 minutes, the request will be “split” into separate custom video requests.
		if (requestDuration > 300) {
			setShowMultipleRequestsModal(true)
		}
		else {
			sendRequest()
		}
	}

	// Prepares array of video clips withg params
	const getVideosList = () => {
		const res = []
		const countRequests = Math.ceil(durationSeconds / 300)

		for (let i = 0; i < countRequests; i++) {
			const start_ts = momenttz(moment(moment(videoDate).format('YYYY-MM-DD ') + startTime).add(300 * i, 'seconds')).utc().format('YYYYMMDDHHmmss')

			let end_ts = moment(start_ts, 'YYYYMMDDHHmmss').add(299, 'seconds').format('YYYYMMDDHHmmss')
			if (i === countRequests - 1) {
				end_ts = momenttz(moment(moment(videoDate).format('YYYY-MM-DD ') + endTime)).utc().format('YYYYMMDDHHmmss')
			}
			const timestamp = moment(start_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')

			res.push({ device_id: selectedDevice.device_id, start_ts, end_ts, timestamp })
		}

		return res
	}

	const sendRequest = (e) => {
		const requestArray = []
		const videosList = getVideosList()
		// console.log('videosList: ', videosList)
		const error = checkForm(videosList)
		if (!error) {
			toggleLoader(true)

			videosList.forEach((item) => {
				requestArray.push(requestEventVideo(item))
			})

			Promise.all(requestArray)
				.then(res => {
					console.log('sendRequest PromiseAll res: ', res)
					toggleLoader(false)

					let eventId = [];
					if (res[0].data.response.request_id) {
						eventId = res[0].data.response.request_id
					} else if (res[0].data.response.saved_files_ids) {
						eventId = res[0].data.response.saved_files_ids
					}

					onSave(
						{
							event_ts: videosList[0].timestamp,
							event_device_id: videosList[0].device_id,
							event_id: eventId,
						}
					)
					setShowMakeAnotherRequestModal(true)
					toastr.success('', 'Customer video request successful')
				})
				.catch((error) => {
					toggleLoader(false)
					console.log('sendRequest PromiseAll catch ERROR: ', error);
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	const validDate = (current) => {
		return current.isSameOrBefore(moment())
	}

	const optsRequest = {}
	if (userRole === 'sales_demo') {
		optsRequest.disabled = 'disabled'
	}

	if (partnerName === 'Rosco Vision WCS' || companyType === 'WCS') {
		displayTimeFormat = 'MM-DD-YYYY hh:mm A'
	}

	const displayItemName = item => item.vehicle_name || item.device_id

	const getItemValue = item => item.vehicle_name || item.device_id

	const matchStateToTerm = (item, value) => {
		if (item.vehicle_name) {
			return item.vehicle_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
		}

		return item.device_id.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
	}

	const setPropTime = (startTimeArg, endTimeArg, isDisabledTime = null) => {
		// if (isDisabledTime !== null) {
		// 	setIsDisabledTime(isDisabledTime)
		// }
		if (startTimeArg) {
			setStartTime(startTimeArg)
			// setFormatStartTime(moment(startTimeArg, 'HH:mm:ss').format('a'))
		}
		else {
			setStartTime('')
		}
		if (endTimeArg) {
			setEndTime(endTimeArg)
			// setFormatEndTime(moment(endTimeArg, 'HH:mm:ss').format('a'))
		}
		else {
			setEndTime('')
		}
	}

	// Custom function to render the day with marking numbers which have video clips
	const renderCalendarDay = (props, currentDate, selectedDate) => {
		// const highlightedDates = [moment("2024-09-10"), moment("2024-09-05"), moment("2024-09-06")]
		const highlightedDates = [...availableVideoDates]
		let classes = props.className
		// console.log('highlightedDates: ', highlightedDates)
		if (highlightedDates.length && highlightedDates.some(date => moment(date).isSame(currentDate, "day"))) {
			classes += " highlighted-day";  // Add custom class for highlighted days
		}

		return (
			<td {...props} className={classes}>
				{currentDate.date()}
			</td>
		)
	}

	const durationSeconds = getDurationSeconds()
	const isRequestDisabled = !selectedDevice || durationSeconds < 5 || durationSeconds > 3600

	return (
		<Modal
			show
			size="lg"
			onHide={onHide}
			dialogClassName="customRequestModal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg" className='action-title' >
					{`Custom Video Request ${propsSelectedDevice && ' - ' + propsSelectedDevice.vehicle_name}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<p style={{ mmarginBottom: propsSelectedDevice ? '0px' : '35px' }}>
						{propsSelectedDevice
							? 'Select a date to retrieve video from:'
							: 'Select a vehicle and a date to retrieve video from:'
						}
					</p>
					<div className="row-flex">
						{!propsSelectedDevice && (
							<div className={`col-xs-12 col-sm-12 col-md-12 form-group vehicle-group${errMessage.err_vehicle ? ' has-error' : ''}`}>
								<Autocomplete
									ref={inputVehicleRef}
									autoHighlight
									value={autoValueDevice}
									inputProps={{
										id: 'states-autocomplete',
										// onFocus: () => setErrMessage({...errMessage, err_vehicle: ''}),
										onBlur: () => setErrMessage({ ...errMessage, err_vehicle: !selectedDevice ? 'required' : '' })
									}}
									wrapperProps={{ className: 'search-autocomplete' }}
									wrapperStyle={{ position: 'relative' }}
									getItemValue={getItemValue}
									shouldItemRender={matchStateToTerm}
									menuStyle={{
										borderRadius: '3px',
										boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
										background: 'rgba(255, 255, 255, 0.9)',
										padding: '2px 0',
										fontSize: '90%',
										position: 'absolute',
										overflow: 'auto',
										maxHeight: '200px',
										left: '0',
										top: 'auto',
										zIndex: 1,
									}}
									onChange={(e, value) => {
										// console.log('onChange value: ', value)
										setAutoValueDevice(value)
										setSelectedDevice('')
									}}
									onSelect={(value, item) => {
										// console.log('onSelect value, item: ', value, item)
										setAutoValueDevice(value)
										setSelectedDevice(item)
									}}
									renderMenu={function (items, value, style) {
										return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
									}}
									renderItem={(item, isHighlighted) => (
										<option
											className={`item ${isHighlighted ? 'item-highlighted' : ''} `}
											key={item.device_id}
										>
											{displayItemName(item)}
										</option>
									)}
									renderInput={props => (
										<div className="select-box">
											<label className="control-label text-label">Vehicle:</label>
											<input {...props} list="list" type="text" value={autoValueDevice} className="vehicle-search" />
										</div>
									)}
									items={devices}
								/>
								{errMessage.err_vehicle && <div className="help-block">REQUIRED</div>}
							</div>
						)}

						<div className="available-dates-nav">
							{/* <div className="arrows">
								<svg className="previous-date" onClick={getPreviousEvents} width='14px' height='18px' viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.277677 6.32924L5.64375 0.963165C6.01464 0.592275 6.61438 0.592275 6.98132 0.963165L7.87304 1.85488C8.24393 2.22577 8.24393 2.82551 7.87304 3.19245L4.07338 7L7.87698 10.8036C8.24787 11.1745 8.24787 11.7742 7.87698 12.1412L6.98527 13.0368C6.61438 13.4077 6.01464 13.4077 5.6477 13.0368L0.281622 7.67076C-0.0932139 7.29987 -0.0932138 6.70013 0.277677 6.32924Z" fill="#0071B6" /></svg>
							</div> */}
							<div className="input event-date">
								<DateTime
									inputProps={{ readOnly: true }}
									value={moment(videoDate).format('MM-DD-YYYY')}
									dateFormat="MM-DD-YYYY"
									timeFormat={false}
									closeOnSelect
									isValidDate={validDate}
									renderDay={renderCalendarDay}
									onChange={onDateChanged}
								/>
							</div>
							{/* <div className="arrows">
								<svg className="next-date" onClick={getNextEvents} width='14px' height='18px' viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.02868 7.67076L2.66261 13.0368C2.29172 13.4077 1.69198 13.4077 1.32503 13.0368L0.433319 12.1451C0.0624288 11.7742 0.0624288 11.1745 0.433319 10.8075L4.23692 7.00394L0.433319 3.20034C0.0624288 2.82945 0.0624288 2.22972 0.433319 1.86277L1.32109 0.963165C1.69198 0.592275 2.29172 0.592275 2.65866 0.963165L8.02473 6.32924C8.39957 6.70013 8.39957 7.29987 8.02868 7.67076Z" fill="#0071B6" /></svg>
							</div> */}
						</div>
					</div>

					<div className="tab-bar-container">
						<div className="tab-buttons">
							<button
								className={`toggle ${(scenario === "simple") && "active"} `}
								active={scenario}
								onClick={() => setScenario('simple')}
							>
								<span>Simple</span>
							</button>
							<button
								className={`toggle ${(scenario === "details") && "active"} `}
								active={scenario}
								onClick={() => setScenario('details')}
							>
								<span>Details</span>
							</button>
						</div>
					</div>

					<div className="bottom-block">
						<div className="text-block">
							Set the <b>Start</b> and <b>End</b> time for the video you wish to retrieve from the camera. <br />
							<br />
							<b>Duration</b> of video request must be:
							<li>Longer than 5 seconds & shorter than 1 hour</li>
							<li>Video requests more than 5 minutes will be divided into multiple custom events no longer than 5 minutes each for the requested duration.</li>
							<br />
							Once finished, click <b>Request Video</b> to send the request to the camera.
						</div>

						<div className="request-time-range">
							<div className={`start-time${errMessage.err_start_time ? ' has-error' : ''}`}>
								<label>Start Time</label>
								<div id="start-time">
									<Flatpickr
										data-enable-time
										noCalendar
										dateFormat="H:i K"
										value={startTime}
										onChange={([time]) => setStartTime(time ? moment(time).format('HH:mm:ss') : null)}
										onClose={() => setErrMessage({ ...errMessage, err_start_time: !startTime ? 'required' : '' })}
										options={{
											enableTime: true,
											noCalendar: true,
											enableSeconds: true,
											minuteIncrement: 1
										}}
									/>
								</div>
								{errMessage.err_start_time && <div className="help-block">REQUIRED</div>}
							</div>
							<div className="text-center">
								<div>Duration</div>
								<div className="duration">
									{durationFormat()}
								</div>
							</div>
							<div className={`end-time${errMessage.err_end_time ? ' has-error' : ''}`}>
								<label>End Time</label>
								<div id="end-time">
									<Flatpickr
										data-enable-time
										noCalendar
										dateFormat="H:i K"
										value={endTime}
										onChange={([time]) => setEndTime(time ? moment(time).format('HH:mm:ss') : null)}
										onClose={() => setErrMessage({ ...errMessage, err_end_time: !endTime ? 'required' : null })}
										options={{
											enableTime: true,
											noCalendar: true,
											enableSeconds: true,
											minuteIncrement: 1
										}}
									/>
								</div>
								{errMessage.err_end_time && <div className="help-block">REQUIRED</div>}
							</div>
						</div>

						{scenario === 'details' &&
							<DetailsScenario
								availableVideos={availableVideos}
								setPropTime={setPropTime}
							/>
						}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary-outline"
					prefix="rosco-primary-cancel btn btn-default"
					onClick={() => onHide()}
				>
					Cancel
				</CustomButton>
				<CustomButton
					variant="primary"
					prefix={`btn ${isRequestDisabled ? 'custom-disabled' : ''} `}
					onClick={() => onClickRequestVideo()}
					disabled={isRequestDisabled}
					{...optsRequest}
				>
					Request Video
				</CustomButton>
			</Modal.Footer>

			{showMultipleRequestsModal &&
				<ModalDelete
					title="Multiple Custom Video Requests"
					content={`<p class="text-center">This request will result in \n ${Math.ceil(durationSeconds / 300)} \n separate Custom Video requests being made for the selected duration \n Continue with request ?</p>`}
					styleView="primary"
					backdropClassName="upper text-center"
					styleBtn={{ width: '157px' }}
					showClose
					closeBtnName="Back"
					deleteBtnName="Submit Requests"
					closeModal={() => setShowMultipleRequestsModal(false)}
					deleteModal={sendRequest}
				/>
			}

			{showMakeAnotherRequestModal &&
				<ModalDelete
					title="Make Another Request?"
					content={`<p class="text-center mt-30 mb-20">Would you like to make another request ?</p>`}
					styleView="primary"
					backdropClassName="upper text-center"
					styleBtn={{ width: '83px' }}
					showClose
					closeBtnName="No"
					deleteBtnName="Yes"
					closeModal={() => { setShowMakeAnotherRequestModal(false); onHide(); }}
					deleteModal={() => setShowMakeAnotherRequestModal(false)}
				/>
			}
		</Modal>
	);
}

CustomVideoRequest.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	propsSelectedDevice: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.objectOf(PropTypes.any),
	]),
	devices: PropTypes.arrayOf(PropTypes.any),
	onHide: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired
}

CustomVideoRequest.defaultProps = {
	propsSelectedDevice: '',
	devices: []
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(CustomVideoRequest);
